<template>
	<div class="flex-row align-items-center">
		<CRow class="justify-content-center headimg mb-3">
			<CCol md="12" class="p-0">
				<div style="background-image: url('img/Dashboard.png'); background-position: 50% 50%; background-size: cover; height: 150px;">
					<h2 class="text-center text-white middle-center"><b>Permohonan  <span class="text-success text-capitalize"> Masa {{status}}</span></b></h2>
				</div>
			</CCol>
		</CRow>
		<div class="container">
			<CRow class="justify-content-center">
				<CCol md="12">
					<CCard class="top-radius-15">
						<CCardHeader class="top-radius-15 bg-success text-white">
							<h4 class="my-0 text-center"><b>List Permohonan</b></h4>
						</CCardHeader>
						<CCardBody>
							<CRow>
								<CCol md="12">
									<table class="table table-sm table-bordered">
                                        <thead class="bg-dark text-white text-center">
                                            <tr>
                                                <th class="align-top" rowspan="2">No</th>
                                                <th>Kode Permohonan</th>
                                                <th>Module</th>
                                                <th>Permohonan</th>
                                                <th>Status</th>
                                                <th class="align-top" rowspan="2" width="150px;">Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template>
                                                <tr v-if="loadingTable">
                                                    <td colspan="11"><i class="fa fa-spin fa-refresh"></i> Loading...</td>
                                                </tr>
                                                <tr v-for="(value_list_permohonan, key_list_permohonan) in list_permohonan" :key="key_list_permohonan" v-else>
                                                    <td class="align-middle text-center">
                                                        <template>
                                                            <div v-if="key_list_permohonan+1 == 10">
                                                                {{curent_page}}0
                                                            </div>
                                                            <div v-else>
                                                                <template>
                                                                    <span v-if="curent_page-1 != 0">
                                                                        {{curent_page-1}}{{key_list_permohonan+1}}
                                                                    </span>
                                                                    <span v-else>
                                                                        {{key_list_permohonan+1}}
                                                                    </span>
                                                                </template>
                                                            </div>
                                                        </template>
                                                    </td>
                                                    <td class="align-middle text-center">{{value_list_permohonan.kode}}</td>
                                                    <td>
                                                        <table class="table table-sm table-borderless">
                                                            <tr>
                                                                <td style="width: 150px">Kategori Module</td>
                                                                <td style="width: 15px">:</td>
                                                                <th>{{value_list_permohonan.pr_kategori}}</th>
                                                            </tr>
                                                            <tr>
                                                                <td>Module</td>
                                                                <td>:</td>
                                                                <th>{{value_list_permohonan.m_nama}}</th>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td>
                                                        <table class="table table-sm table-borderless">
                                                            <!-- <tr>
                                                                <td style="width: 150px">Nomor Surat</td>
                                                                <td style="width: 15px">:</td>
                                                                <th>{{value_list_permohonan.pr_nomor_surat}}</th>
                                                            </tr> -->
                                                            <tr>
                                                                <td>Tanggal Pengajuan</td>
                                                                <td>:</td>
                                                                <th>
                                                                    
                                                                    <template>

                                                                        <span v-if="value_list_permohonan.pr_tgl_pengajuan">{{value_list_permohonan.pr_tgl_pengajuan}}</span>
                                                                        <span v-else>Data Tidak Tersedia</span>
                                                                    </template>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td>Tanggal Verifikasi</td>
                                                                <td>:</td>
                                                                <th>
                                                                    <template>
                                                                        <span v-if="value_list_permohonan.pr_tgl_verifikasi">{{value_list_permohonan.pr_tgl_verifikasi}}</span>
                                                                        <span v-else>Data Tidak Tersedia</span>
                                                                    </template>
                                                                </th>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td class="align-middle text-center">
                                                        <template>
                                                            <CBadge :color="getBadge(value_list_permohonan.status_permohonan)" v-if="value_list_permohonan.status_permohonan == 'Pengajuan Permohonan'  || value_list_permohonan.status_permohonan ==  'Draft Permohonan'">
                                                                <div>
                                                                    Draft
                                                                </div>
                                                            </CBadge>
                                                            <CBadge :color="getBadge(value_list_permohonan.status_permohonan)" v-else>
                                                                <div>
                                                                    {{value_list_permohonan.status_permohonan}}
                                                                </div>
                                                            </CBadge>
                                                        </template>
                                                    </td>
                                                    <td class="align-middle text-center">
                                                        <template>
                                                            <div v-if="value_list_permohonan.pr_kategori == 'Hortikultura'">
                                                                <template>
                                                                    <div v-if="value_list_permohonan.is_pemasukan">
                                                                        <template>
                                                                            <div v-if="value_list_permohonan.status_permohonan ==  'Menunggu Perbaikan'">
                                                                                <router-link :to="{path: 'edit-perbaikan-permohonan-pemasukan/'+value_list_permohonan.pr_kategori+'/'+value_list_permohonan.id}" class="btn btn-block btn-warning btn-sm"><i class="fa fa-pencil"></i> Edit</router-link>
                                                                            </div> 
                                                                            <div v-else-if="value_list_permohonan.status_permohonan ==  'Pengajuan Permohonan' || value_list_permohonan.status_permohonan ==  'Draft Permohonan'">
                                                                                <router-link :to="{path: 'edit-permohonan-pemasukan-draft/'+value_list_permohonan.pr_kategori+'/'+value_list_permohonan.id}" class="btn btn-block btn-warning btn-sm"><i class="fa fa-pencil"></i> Edit</router-link>
                                                                                <div v-on:click="deletePermohonan(value_list_permohonan.id)" class="btn btn-block btn-danger btn-sm"><i class="fa fa-times"></i> Hapus</div>
                                                                            </div>
                                                                            <div v-else>
                                                                                <router-link :to="{path: 'detail-permohonan-pemasukan/'+value_list_permohonan.pr_kategori.replace(' ','-')+'/'+value_list_permohonan.id}" class="btn btn-block btn-info btn-sm"><i class="fa fa-eye"></i> Detail</router-link>
                                                                            </div>
                                                                        </template>
                                                                    </div>
                                                                    <div v-else>
                                                                        <template>
                                                                            <div v-if="value_list_permohonan.status_permohonan ==  'Menunggu Perbaikan'">
                                                                                <router-link :to="{path: 'edit-perbaikan-permohonan-pengeluaran/'+value_list_permohonan.pr_kategori+'/'+value_list_permohonan.id}" class="btn btn-block btn-warning btn-sm"><i class="fa fa-pencil"></i> Edit</router-link>
                                                                            </div>
                                                                            <div v-else-if="value_list_permohonan.status_permohonan ==  'Pengajuan Permohonan' || value_list_permohonan.status_permohonan ==  'Draft Permohonan'">
                                                                                <router-link :to="{path: 'edit-permohonan-pengeluaran-draft/'+value_list_permohonan.pr_kategori+'/'+value_list_permohonan.id}" class="btn btn-block btn-warning btn-sm"><i class="fa fa-pencil"></i> Edit</router-link>
                                                                                <div v-on:click="deletePermohonan(value_list_permohonan.id)" class="btn btn-block btn-danger btn-sm"><i class="fa fa-times"></i> Hapus</div>
                                                                            </div>
                                                                            <div v-else>
                                                                                <router-link :to="{name: 'DetailPermohonanPengeluaranPemohon', params: { idpermohonan: value_list_permohonan.id }}" class="btn btn-block btn-info btn-sm"><i class="fa fa-eye"></i> Detail</router-link>
                                                                            </div>
                                                                        </template>
                                                                    </div>
                                                                </template>
                                                            </div>
                                                            <div v-else>
                                                                <template>
                                                                    <div v-if="value_list_permohonan.is_pemasukan">
                                                                        <template>
                                                                            <div v-if="value_list_permohonan.status_permohonan ==  'Menunggu Perbaikan'">
                                                                                <router-link :to="{name: 'EditPermohonanPemasukanPemohon', params: { idpermohonan: value_list_permohonan.id }}" class="btn btn-block btn-warning btn-sm"><i class="fa fa-pencil"></i> Edit</router-link>
                                                                            </div>
                                                                            <div v-else-if="value_list_permohonan.status_permohonan ==  'Pengajuan Permohonan' || value_list_permohonan.status_permohonan ==  'Draft Permohonan'">
                                                                                <router-link :to="{name: 'EditPemasukanDraft', params: { idpermohonan: value_list_permohonan.id }}" class="btn btn-block btn-warning btn-sm"><i class="fa fa-pencil"></i> Edit</router-link>
                                                                                <div v-on:click="deletePermohonan(value_list_permohonan.id)" class="btn btn-block btn-danger btn-sm"><i class="fa fa-times"></i> Hapus</div>
                                                                            </div>
                                                                            <div v-else>
                                                                                <router-link :to="{name: 'DetailPermohonanPemasukanPemohon', params: { idpermohonan: value_list_permohonan.id }}" class="btn btn-block btn-info btn-sm"><i class="fa fa-eye"></i> Detail</router-link>
                                                                            </div>
                                                                        </template>
                                                                    </div>
                                                                    <div v-else>
                                                                        <template>
                                                                            <div v-if="value_list_permohonan.status_permohonan ==  'Menunggu Perbaikan'">
                                                                                <router-link :to="{name: 'EditPermohonanPengeluaranPemohon', params: { idpermohonan: value_list_permohonan.id }}" class="btn btn-block btn-warning btn-sm"><i class="fa fa-pencil"></i> Edit</router-link>
                                                                            </div>
                                                                            <div v-else-if="value_list_permohonan.status_permohonan ==  'Pengajuan Permohonan' || value_list_permohonan.status_permohonan ==  'Draft Permohonan'">
                                                                                <router-link :to="{name: 'EditPengeluaranDraft', params: { idpermohonan: value_list_permohonan.id }}" class="btn btn-block btn-warning btn-sm"><i class="fa fa-pencil"></i> Edit</router-link>
                                                                                <div v-on:click="deletePermohonan(value_list_permohonan.id)" class="btn btn-block btn-danger btn-sm"><i class="fa fa-times"></i> Hapus</div>
                                                                            </div>
                                                                            <div v-else>
                                                                                <router-link :to="{name: 'DetailPermohonanPengeluaranPemohon', params: { idpermohonan: value_list_permohonan.id }}" class="btn btn-block btn-info btn-sm"><i class="fa fa-eye"></i> Detail</router-link>
                                                                            </div>
                                                                        </template>
                                                                        
                                                                        
                                                                    </div>
                                                                </template>
                                                            </div>
                                                        </template>
                                                        
                                                        <template>
                                                            <div v-if="value_list_permohonan.status_permohonan ==  'Selesai' && value_list_permohonan.pr_status_realisasi != 'lengkap'">
                                                                <router-link :to="{name: 'LaporanPemohonRealisasi', params: { idpermohonan: value_list_permohonan.id }}" class="btn btn-block btn-success btn-sm mt-1"><i class="fa fa-hand-o-right"></i> Lapor Realisasi</router-link>
                                                            </div>
                                                            <div v-else-if="value_list_permohonan.status_permohonan ==  'Selesai' && value_list_permohonan.pr_status_realisasi == 'lengkap'">
                                                                <div class="alert alert-success mt-1">Realisasi Sudah Sesuai</div>
                                                            </div>
                                                            <div v-else>
                                                            </div>
                                                        </template>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
									<nav aria-label="pagination">
										<ul class="pagination justify-content-start">
											<!---->
											<!-- {{ page.active ? 'active' : '' }} -->
											<li v-for="(page, key_page) in paging" v-bind:class="{ 'active': page.active }" :key="key_page" class="page-item">
												<div v-on:click="toPage(page.url)" class="page-link c-page-link-number">
													<div v-html="page.label"></div>
												</div>
											</li>
										</ul>
									</nav>
								</CCol>
							</CRow>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</div>
	</div>
</template>
<script>
	const null_array = [];
	export default {
		name: "PermohonanPemohonProses",
		data() {
			return {
				list_permohonan: null_array,
				paging: null_array,
				curent_page: '',
				session_data: JSON.parse(this.session),
				status: this.$route.params.status,
                curent_page: '',
                loadingTable: true,
			};
		},
		methods: {
			getBadge(status) {
				switch (status) {
					case "draft":
						return "bg-dark";
					case "Menunggu Perbaikan":
						return "warning";
					case "Ditolak":
						return "danger";
					case "Selesai":
						return "success";
					case "ajukan":
						return "info";
                    case "Dibatalkan":
						return "danger";
					default:
						return "info";
				}
			},
			toPage(url) {
                this.loadingTable = true;
                var numpage = url.replace(this.apiLink+'api/permohonan?page=','');
                axios.get(url, {
                	params:{
	            		status: this.status ,
	            	},
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var res_permohonan = response.data;
                    var res_permohonan_data = res_permohonan.data[0];
                    // console.log(res_permohonan_data);
                    if (res_permohonan.meta.code != "200") {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: res_permohonan.data.message
                        });
                    }
                    else{
                        this.loadingTable = false;
                        this.list_permohonan = res_permohonan_data.data;
	                    this.paging = res_permohonan_data.links;
	                    this.curent_page = res_permohonan_data.current_page;
                        this.curent_page = res_permohonan_data.current_page;
                    }
                });
            },
		},
		
		mounted() {
            axios.get(this.apiLink + "api/permohonan", {
            	params:{
            		nib: this.session_data.nib,
            		status: this.status ,
            	},
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_permohonan = response.data;
                var res_permohonan_data = res_permohonan.data[0];
                // console.log(res_permohonan_data);
                if (res_permohonan.meta.code != "200") {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: res_permohonan.data.message,
                    });
                } else {
                    this.loadingTable = false;
                    this.list_permohonan = res_permohonan_data.data;
                    this.paging = res_permohonan_data.links;
                    this.curent_page = res_permohonan_data.current_page;
                    this.curent_page = res_permohonan_data.current_page;
                }
            });
        },
	};
</script>
